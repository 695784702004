import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { IModule } from '../../../../../interfaces/IModule';
import { EpisodeOfCareService } from '../../../../../services/episodeOfCare.service';
import { paths } from 'src/component-paths/component-paths';
import { combineLatest, debounceTime, Subject } from 'rxjs';
import { SocialHistoryService } from 'src/app/services/socialHistory.service';

@Component({
  selector: 'app-social-history-section',
  templateUrl: './social-history-section.component.html',
  styleUrls: ['./social-history-section.component.css'],
})
export class SocialHistorySectionComponent {
  @Input() submenu?: IModule[];
  componentPaths = paths;

  @Input() selectedOption?: string;
  @Output() selectedOptionChange = new EventEmitter<string>();

  ngOnInit() {}

  private scrollSubject = new Subject<Event>();

  ngAfterViewInit() {
    setTimeout(() => {
      combineLatest([
        this.socialHistoryService.socialHistoryLoadingSub$,
      ]).subscribe(([value1]) => {
        const sectionPath = this.submenu?.find(
          (sm) => sm.path == this.selectedOption
        )?.path;
        if (!value1 && sectionPath) {
          const element = document.getElementById(sectionPath);
          if (element) {
            // menu, patient header, title, margin
            const offset = element.offsetTop - 120 - 66 - 64 - 16;
            window.scroll({
              top: offset,
              behavior: 'smooth',
            });

            this.scrollSubject
              .pipe(
                debounceTime(100) // Adjust debounce time as needed (in milliseconds)
              )
              .subscribe(() => {
                // Your existing scroll logic here
                // const sho = document.getElementById(
                //   paths.socialHistoryObservations
                // );
                // const shq = document.getElementById(
                //   paths.socialHistoryQuestionnaire
                // );
                // if (shq) {
                //   const rect = shq.getBoundingClientRect();
                //   if (rect.top >= 120) {
                //     this.selectedOption = this.submenu?.find(
                //       (sm) => sm.path == paths.socialHistoryQuestionnaire
                //     )?.id;
                //     this.selectedOptionChange.emit(this.selectedOption);
                //   }
                // }
                // if (sho) {
                //   const rect = sho.getBoundingClientRect();
                //   if (rect.top >= 120) {
                //     this.selectedOption = this.submenu?.find(
                //       (sm) => sm.path == paths.socialHistoryObservations
                //     )?.id;
                //     this.selectedOptionChange.emit(this.selectedOption);
                //   }
                // }
              });
          }
        }
      });
    }, 500);
  }

  constructor(private readonly socialHistoryService: SocialHistoryService) {}

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    this.scrollSubject.next(event);
  }
}
