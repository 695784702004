import { Component, HostListener, OnInit } from '@angular/core';
import { IUserManagementNew } from '../../../../interfaces/IUserManagementNew';
import { UserManagementService } from '../../../../services/userManagement.service';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input-gg';

@Component({
  selector: 'app-user-management-new',
  templateUrl: './user-management-new.component.html',
  styleUrls: ['./user-management-new.component.css'],
})
export class UserManagementNewComponent implements OnInit {
  roles: any[] = [];
  locales: any[] = [];
  loading = false;

  personalInfoForm!: FormGroup;
  contactInfoForm!: FormGroup;
  rolesAndLanguageForm!: FormGroup;

  externalDoctors: any[] = []; // List of external doctors
  isExternalDoctor = false; // Toggle state
  selectedExternalDoctor: any = null; // Selected doctor

  passwordCriteria = {
    minLength: false,
    upperLowerCase: false,
    digits: false,
    specialChars: false,
    notUsername: false,
  };
  hideTempPassword: boolean = true;
  hideConfirmPassword: boolean = true;

  usernamePattern = /^[a-zA-Z0-9.]{6,20}$/; // only allow latin letters,numbers and dots, min 6, max 20

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Cyprus, CountryISO.Greece];

  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event: BeforeUnloadEvent) {
    event.preventDefault();
    event.returnValue = '';
  }

  newUser: IUserManagementNew = {
    userRegistration: {
      username: '',
      email: '',
      firstName: '',
      lastName: '',
      credentials: [{ value: '' }],
      attributes: {
        locale: '',
        phoneNumber: '',
      },
    },
    userRole: [],
  };

  constructor(
    private readonly userManagement: UserManagementService,
    private readonly router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.fetchDropdownData();
    this.buildForm();
  }

  buildForm() {
    this.personalInfoForm = this.formBuilder.group({
      firstName: [this.newUser.userRegistration.firstName, Validators.required],
      lastName: [this.newUser.userRegistration.lastName, Validators.required],
      username: [
        this.newUser.userRegistration.username,
        [Validators.required, , Validators.pattern(this.usernamePattern)],
      ],
      tempPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          this.passwordPolicyValidator.bind(this),
        ],
      ],
      confirmTempPassword: [
        { value: '', disabled: true },
        [Validators.required, this.passwordsMatchValidator.bind(this)],
      ],
    });

    this.personalInfoForm
      .get('tempPassword')
      ?.valueChanges.subscribe((value) => {
        this.updatePasswordCriteria(value);
        const tempPasswordControl = this.personalInfoForm.get('tempPassword');
        if (tempPasswordControl && tempPasswordControl.valid) {
          this.personalInfoForm.get('confirmTempPassword')?.enable();
        } else {
          this.personalInfoForm.get('confirmTempPassword')?.disable();
          this.personalInfoForm.get('confirmTempPassword')?.reset();
        }
      });

    this.contactInfoForm = this.formBuilder.group({
      email: [
        this.newUser.userRegistration.email,
        [Validators.required, Validators.email],
      ],
      phoneNumber: [undefined, Validators.required],
    });

    this.rolesAndLanguageForm = this.formBuilder.group({
      roles: [this.newUser.userRole, Validators.required],
      locale: [
        this.newUser.userRegistration.attributes.locale,
        Validators.required,
      ],
    });
  }

  updatePasswordCriteria(password: string) {
    if (!password) {
      // Reset criteria if the password is null or empty
      this.passwordCriteria = {
        minLength: false,
        upperLowerCase: false,
        digits: false,
        specialChars: false,
        notUsername: false,
      };
      return;
    }
    const username = this.personalInfoForm.get('username')?.value;

    this.passwordCriteria.minLength = password.length >= 8;
    this.passwordCriteria.upperLowerCase =
      /[A-Z]/.test(password) && /[a-z]/.test(password);
    this.passwordCriteria.digits = /[0-9]/.test(password);
    this.passwordCriteria.specialChars = /[!@#$%^&*(),.?":{}|<>]/.test(
      password
    );
    this.passwordCriteria.notUsername = password !== username;
  }

  passwordPolicyValidator(
    control: AbstractControl
  ): { [key: string]: any } | null {
    const password = control.value;
    if (!password) {
      return null;
    }

    const username = this.personalInfoForm.get('username')?.value;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /[0-9]/.test(password);
    const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    let propertiesCount = 0;
    if (hasUpperCase) propertiesCount++;
    if (hasLowerCase) propertiesCount++;
    if (hasNumbers) propertiesCount++;
    if (hasSpecialChars) propertiesCount++;

    if (propertiesCount < 2 || password.length < 8 || password === username) {
      return { passwordPolicy: true };
    }

    return null;
  }

  passwordsMatchValidator(
    control: AbstractControl
  ): { [key: string]: any } | null {
    const confirmPassword = control.value;
    const password = this.personalInfoForm.get('tempPassword')?.value;
    if (password !== confirmPassword) {
      return { passwordMismatch: true };
    }
    return null;
  }

  togglePasswordVisibility(field: string) {
    if (field === 'tempPassword') {
      this.hideTempPassword = !this.hideTempPassword;
    } else if (field === 'confirmPassword') {
      this.hideConfirmPassword = !this.hideConfirmPassword;
    }
  }

  createUser() {
    // Update this.newUser with form values
    this.newUser.userRegistration.username =
      this.personalInfoForm.get('username')?.value;
    this.newUser.userRegistration.firstName =
      this.personalInfoForm.get('firstName')?.value;
    this.newUser.userRegistration.lastName =
      this.personalInfoForm.get('lastName')?.value;
    // Set credentials value
    this.newUser.userRegistration.credentials[0].value =
      this.personalInfoForm.get('tempPassword')?.value;

    this.newUser.userRegistration.email =
      this.contactInfoForm.get('email')?.value;
    this.newUser.userRegistration.attributes.phoneNumber =
      this.contactInfoForm.get('phoneNumber')?.value.e164Number;

    // Set userRole.id and userRole.name based on the selected role
    const selectedRoleIds = this.rolesAndLanguageForm.get('roles')?.value;

    this.newUser.userRole = this.roles
      .filter((r) => selectedRoleIds?.includes(r.id))
      .map((r) => {
        return {
          name: r.name,
          id: r.id,
        };
      });

    const selectedLocaleId = this.rolesAndLanguageForm.get('locale')?.value;
    const selectedLocale = this.locales.find(
      (locale) => locale.id == selectedLocaleId
    );
    this.newUser.userRegistration.attributes.locale = selectedLocale?.code;

    this.loading = true;

    // Send the request to create a new user
    this.userManagement
      .newUser(this.newUser)
      .subscribe({
        next: (response) => {
          Swal.fire({
            text: 'New user created successfully!',
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#0d9488',
            timer: 3000,
          });
          this.personalInfoForm.markAsPristine();
          this.contactInfoForm.markAsPristine();
          this.rolesAndLanguageForm.markAsPristine();
          this.router.navigate(['ehr/userManagement']);
        },
        error: (err) => {
          Swal.fire({
            text: `Unable to create user! ${err.error.message}`,
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });
          console.error('Error creating new user:', err);
        },
      })
      .add(() => {
        // Hide loading indicator
        this.loading = false;
      });
  }

  nextStep(stepper: MatStepper, stepIncrement: number): void {
    const currentStep = stepper.selectedIndex;
    const nextStep = currentStep + stepIncrement;
    let isValidStep = true;

    let currentStepForm: FormGroup | undefined;
    switch (currentStep) {
      case 0:
        currentStepForm = this.personalInfoForm;
        break;
      case 1:
        currentStepForm = this.contactInfoForm;
        break;
      case 2:
        currentStepForm = this.rolesAndLanguageForm;
        break;
      default:
        break;
    }

    if (currentStepForm) {
      Object.keys(currentStepForm.controls).forEach((controlName) => {
        const control = currentStepForm.get(controlName);
        control?.markAsTouched();
      });

      if (currentStep === 0) {
        const username = this.personalInfoForm.get('username')?.value;
        this.userManagement.usernameExists(username).subscribe({
          next: (response) => {
            isValidStep = true;

            // Check if the form is valid before proceeding to the next step
            if (nextStep >= 0 && nextStep < stepper._steps.length) {
              if (isValidStep && currentStepForm.valid) {
                stepper.selectedIndex = nextStep;
              } else {
                if (stepIncrement === -1 && stepper.selectedIndex !== 0) {
                  stepper.selectedIndex += stepIncrement;
                } else {
                  Swal.fire({
                    text: 'Please fill all required fields and ensure that passwords match and meet the password policy!',
                    toast: true,
                    position: 'bottom-end',
                    showCancelButton: false,
                    showConfirmButton: false,
                    color: 'white',
                    background: '#ff6969',
                    timer: 3000,
                  });
                }
              }
            } else if (nextStep < 0 && currentStep > 0) {
              stepper.selectedIndex = nextStep;
            }
          },
          error: (error) => {
            isValidStep = false;
            Swal.fire({
              text: 'This username already exists. Please choose another one.',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            return;
          },
        });
      } else {
        if (nextStep >= 0 && nextStep < stepper._steps.length) {
          if (currentStepForm.valid) {
            stepper.selectedIndex = nextStep;
          } else {
            if (stepIncrement === -1 && stepper.selectedIndex !== 0) {
              stepper.selectedIndex += stepIncrement;
            } else {
              Swal.fire({
                text: 'Please fill all required fields and ensure that passwords match and meet the password policy!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
            }
          }
        } else if (nextStep < 0 && currentStep > 0) {
          stepper.selectedIndex = nextStep;
        }
      }
    }
  }

  fetchDropdownData() {
    // Fetch dropdown data when the component initializes
    this.userManagement.getDropdownData().subscribe({
      next: (data) => {
        this.roles = data.data.roles.map((role: any) => ({
          ...role,
          translatedName: this.translate.instant(role.name),
          name: role.name,
        }));
        this.locales = data.data.locale;
        this.externalDoctors = data.data.extenralDoctor || [];
      },
      error: (err) => {
        console.error('Error fetching dropdown data:', err);
      },
    });
  }

  cancelCreate() {
    this.router.navigate(['ehr/userManagement']);
  }

  protected readonly oncancel = oncancel;

  getPhoneNumberControl(): FormControl<string | null> {
    return (
      (this.contactInfoForm.get('phoneNumber') as FormControl<string | null>) ||
      new FormControl<string | null>(null)
    );
  }

  // Handle toggle change
  onExternalDoctorToggle(isExternal: boolean) {
    this.isExternalDoctor = isExternal;

    if (!isExternal) {
      this.selectedExternalDoctor = null;
      this.personalInfoForm.reset();
      this.contactInfoForm.reset();
      this.rolesAndLanguageForm.reset();

      // Clear and reapply validators for password fields
      const tempPasswordControl = this.personalInfoForm.get('tempPassword');
      const confirmPasswordControl = this.personalInfoForm.get(
        'confirmTempPassword'
      );

      tempPasswordControl?.clearValidators();
      confirmPasswordControl?.clearValidators();

      tempPasswordControl?.updateValueAndValidity();
      confirmPasswordControl?.updateValueAndValidity();

      // Reapply validators
      tempPasswordControl?.setValidators([
        Validators.required,
        Validators.minLength(8),
        this.passwordPolicyValidator.bind(this),
      ]);
      confirmPasswordControl?.setValidators([
        Validators.required,
        this.passwordsMatchValidator.bind(this),
      ]);

      tempPasswordControl?.updateValueAndValidity();
      confirmPasswordControl?.updateValueAndValidity();
    }
  }

  // Handle external doctor selection
  onExternalDoctorSelected(doctor: any) {
    if (doctor) {
      this.personalInfoForm.patchValue({
        firstName: doctor.name,
        lastName: doctor.surname,
      });
      this.contactInfoForm.patchValue({
        phoneNumber:
          doctor.phoneNumber && doctor.phoneNumber.trim()
            ? doctor.phoneNumber
            : doctor.landLinePhone,
      });
      const doctorRole = this.roles.find((role) => role.name === 'Doctor');
      if (doctorRole) {
        this.rolesAndLanguageForm.patchValue({ roles: [doctorRole.id] });
      }
    }
  }
}
