import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';
import { UserManagementNewComponent } from '../components/ehr/user-management/user-management-new/user-management-new.component';
import { UserManagementEditComponent } from '../components/ehr/user-management/user-management-edit/user-management-edit.component';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class UnsavedChangesGuard
  implements
    CanDeactivate<UserManagementNewComponent | UserManagementEditComponent>
{
  constructor(private dialog: MatDialog) {}

  canDeactivate(
    component: UserManagementNewComponent | UserManagementEditComponent
  ): Observable<boolean> | boolean {
    // Check if we are in UserManagementNewComponent
    if (component instanceof UserManagementNewComponent) {
      if (
        component.personalInfoForm?.dirty ||
        component.contactInfoForm?.dirty ||
        component.rolesAndLanguageForm?.dirty
      ) {
        return this.showDiscardChangesDialog();
      }
    }
    // Check if we are in UserManagementEditComponent
    else if (component instanceof UserManagementEditComponent) {
      if (component.formGroup?.dirty) {
        return this.showDiscardChangesDialog();
      }
    }
    return true;
  }

  showDiscardChangesDialog(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      Swal.fire({
        title: 'Are you sure?',
        text: 'All unsaved data will be lost. Do you want to continue?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, discard changes',
        cancelButtonText: 'No, keep editing',
        confirmButtonColor: '#0d9488', // Direct styling for confirm button
        cancelButtonColor: '#dc3741', // Direct styling for cancel button
        didOpen: () => {
          // Add hover styles using inline CSS
          const style = document.createElement('style');
          style.innerHTML = `
            .swal2-confirm:hover {
              background-color: #0c7b75 !important; /* Darker shade for confirm button on hover */
            }
            .swal2-cancel:hover {
              background-color: #b83038 !important; /* Darker shade for cancel button on hover */
            }
            .swal2-cancel {
              order: 0;
              border-radius: 0.25em;
              background: initial;
              color: #fff;
              font-size: 1em;
            }
          `;
          document.head.appendChild(style);
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // User clicked "Discard Changes"
          observer.next(true);
        } else if (result.isDenied) {
          // User clicked "Save as Draft"
          // Handle saving logic here if needed (e.g., save changes to a draft)
          // For now, we just resolve with true
          observer.next(true);
        } else {
          // User clicked "Keep Editing"
          observer.next(false);
        }
        observer.complete();
      });
    });
  }
}
