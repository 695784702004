<ng-container *ngFor="let package of data" id="">
    <nz-layout>
        <nz-content>
            <ng-container>
                <app-reusable-dynamic-table [title]="package.groupName || '' || ''" [groupId]="package.groupId || 0"
                    [dropdownData]="package.items || []" colIdentifier="date" [isPreview]="false" [pagination]="true"
                    [endpoint]="endpoint" />
            </ng-container>
        </nz-content>
    </nz-layout>
</ng-container>