<nz-layout class="parent">
    <nz-content class="parent">

        <ng-container *ngFor="let tabcont of submenu?.childModules; let j = index; ">
            <app-arterial-blood-gas [removeMargin]="true" *ngIf="tabcont.path == componentPaths.arterialBloodGas" />
            <app-vital-signs *ngIf="tabcont.path == componentPaths.vitalSigns" />
            <app-glasgow *ngIf="tabcont.path == componentPaths.glasgow" />

            <app-capnography *ngIf="tabcont.path == componentPaths.capnography" />

            <nz-layout class="child" *ngIf="tabcont.path == componentPaths.complication">
                <nz-header class="table-header">
                    <div fxLayout="row" class="table-header-row">
                        <div fxLayout="col" fxLayoutAlign="start center" fxFlex.gt-xl="15" fxFlex.xl="15"
                            fxFlex.lt-xl="35" class="title">
                            {{"Complications" | translate}}
                        </div>
                    </div>
                </nz-header>
                <nz-content class="child"><app-complication /></nz-content>
            </nz-layout>
        </ng-container>

    </nz-content>
</nz-layout>