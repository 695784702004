<nz-modal #modalRef [(nzVisible)]="isVisible" [nzTitle]="((isEdit ? 'Edit' : 'Add') + ' Allergy') | translate"
    (nzOnCancel)="handleCancel()" nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null"
    class="custom-modal-class">
    <ng-container *nzModalContent>
        <form [formGroup]="allergyForm" (ngSubmit)="submitForm()"
            [ngClass]="{'allergy-submitted': allergySubmitted, 'allergy-not-submitted': !allergySubmitted}">
            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Type" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs allergy" nzShowSearch nzAllowClear [nzPlaceHolder]="'Type' | translate"
                        formControlName="typeValue" [nzLoading]="isLoading">
                        <ng-container *ngFor="let et of allergyDropdown?.type">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Substance/Product" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs allergy" nzShowSearch nzAllowClear
                        [nzPlaceHolder]="'Substance/Product' | translate" formControlName="allergyValue"
                        [nzLoading]="isLoading" [nzOptions]="substancesProducts" nzNoAnimation>
                        <!-- <ng-container *ngFor="let sp of allergyDropdown?.substanceProduct">
                            <nz-option [nzLabel]="sp.name" [nzValue]="sp.id"></nz-option>
                        </ng-container> -->
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Category" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs allergy"
                        [placeholder]="'Category based on Substance/Product' | translate " [value]="findCategory()"
                        [disabled]="true" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Criticality" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs allergy" nzShowSearch nzAllowClear
                        [nzPlaceHolder]="'Criticality' | translate" formControlName="criticalityValue"
                        [nzLoading]="isLoading">
                        <ng-container *ngFor="let et of allergyDropdown?.criticality">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Status" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs allergy" nzShowSearch nzAllowClear [nzPlaceHolder]="'Status' | translate"
                        formControlName="statusValue" [nzLoading]="isLoading">
                        <ng-container *ngFor="let et of allergyDropdown?.status">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>

            <div *ngIf="allergyForm.value.statusValue==3" class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Resolution Date" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs allergy" type="date" [placeholder]="'Resolution Date' | translate"
                        formControlName="resolutionDate" [min]="getMinDate() ||
                        this.reactionsForm.value?.endDate ||
                        this.reactionsForm.value?.onsetDate" [max]="getCurrentDate()" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Description" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs allergy" type="text" [placeholder]="'Description...' | translate"
                        formControlName="description" />
                </div>
            </div>


            <!-- Reactions Part -->
            <div class="reactions-container"
                [ngClass]="{'reactions-submitted': reactionsSubmitted, 'reactions-not-submitted': allergySubmitted}">
                <h2>{{"Add Reaction(s)" | translate}}<span class="optional">{{"(optional)" | translate}}</span></h2>
                <form [formGroup]="reactionsForm">
                    <div class="form-row" fxLayout="row" fxLayoutAlign="center center">
                        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="20" fxLayoutAlign="center center">
                            <label>{{"Manifestation" | translate}}</label>
                        </div>
                        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="80">
                            <nz-select class="inputs reaction" nzShowSearch nzAllowClear
                                [nzPlaceHolder]="'Manifestation' | translate" formControlName="manifestationValue"
                                [nzLoading]="isLoading">
                                <ng-container *ngFor="let et of allergyDropdown?.manifestation">
                                    <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                                </ng-container>
                            </nz-select>
                        </div>

                        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="20" class="left-margin"
                            fxLayoutAlign="center center">
                            <label>{{"Description" | translate}}</label>
                        </div>
                        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="80">
                            <input nz-input class="inputs reaction" type="text"
                                [placeholder]="'Description...' | translate" formControlName="description" />
                        </div>
                    </div>

                    <div class="form-row" fxLayout="row" fxLayoutAlign="center center">
                        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="20" fxLayoutAlign="center center">
                            <label>{{"Onset Date" | translate}}</label>
                        </div>
                        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="80">
                            <input nz-input class="inputs reaction" type="date" [placeholder]="'Onset Date' | translate"
                                formControlName="onsetDate"
                                [max]="this?.allergyForm?.value?.resolutionDate|| this?.reactionsForm?.value?.endDate  || getCurrentDate()" />
                        </div>

                        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="20" class="left-margin"
                            fxLayoutAlign="center center">
                            <label>{{"End Date" | translate}}</label><span class="optional">{{"(optional)" |
                                translate}}</span>
                        </div>
                        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="80">
                            <input nz-input class="inputs reaction" type="date" [placeholder]="'End Date' | translate"
                                formControlName="endDate" [min]="this?.reactionsForm?.value?.onsetDate"
                                [max]="this?.allergyForm?.value?.resolutionDate||getCurrentDate()" />
                        </div>
                    </div>

                    <div class="form-row" fxLayout="row" fxLayoutAlign="center center">
                        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="20" fxLayoutAlign="center center">
                            <label>{{"Severity" | translate}}</label>
                        </div>
                        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="80">
                            <nz-select class="inputs reaction" nzShowSearch nzAllowClear
                                [nzPlaceHolder]="'Severity' | translate" formControlName="severityValue"
                                [nzLoading]="isLoading">
                                <ng-container *ngFor="let et of allergyDropdown?.severity">
                                    <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                                </ng-container>
                            </nz-select>
                        </div>

                        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="20" class="left-margin"
                            fxLayoutAlign="center center">
                            <label>{{"Certainty" | translate}}</label>
                        </div>
                        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="80">
                            <nz-select class="inputs reaction" nzShowSearch nzAllowClear
                                [nzPlaceHolder]="'Certainty' | translate" formControlName="certaintyValue"
                                [nzLoading]="isLoading">
                                <ng-container *ngFor="let et of allergyDropdown?.certainty">
                                    <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                                </ng-container>
                            </nz-select>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="end" class="footer">
                        <button nz-button nzPrimary class="add-btn" type="button" (click)="addReaction()">{{"Add" |
                            translate}}</button>
                    </div>

                    <nz-layout *ngIf="reactionsAddedDetailed.length > 0">
                        <nz-header class="table-header">
                            <div fxLayout="row" class="table-header-row">
                                <div fxLayout="col" fxLayoutAlign="start center" fxFlex.gt-xl="15" fxFlex.xl="15"
                                    fxFlex.lt-xl="35" class="title">
                                    {{"Reactions" | translate}}
                                </div>
                            </div>

                        </nz-header>
                        <nz-content>
                            <nz-table #nzTable [nzData]="reactionsAddedDetailed || []" nzTableLayout="fixed"
                                nzShowTitle="false" nzSize="middle">
                                <thead>
                                    <tr>
                                        <ng-container>
                                            <th>{{"Manifestation" | translate}}
                                                <app-tooltip fieldName="Manifestation" area="Reactions" />
                                            </th>

                                            <th>{{"Onset Date" | translate}}
                                                <app-tooltip fieldName="Manifestation" area="Reactions" />
                                            </th>

                                            <th>{{"End Date" | translate}}
                                                <app-tooltip fieldName="Manifestation" area="Reactions" />
                                            </th>

                                            <th>{{"Severity" | translate}}
                                                <app-tooltip fieldName="Manifestation" area="Reactions" />
                                            </th>

                                            <th>{{"Certainty" | translate}}
                                                <app-tooltip fieldName="Manifestation" area="Reactions" />
                                            </th>

                                            <th>{{"Description" | translate}}
                                                <app-tooltip fieldName="Manifestation" area="Reactions" />
                                            </th>

                                            <th nzWidth="40px"></th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let item of reactionsAddedDetailed">
                                        <tr>
                                            <ng-container>
                                                <td>{{item?.manifestation || "-"}}</td>
                                                <td>{{item?.onSetDate || "-"}}</td>
                                                <td>{{item?.endDate || "-"}}</td>
                                                <td>{{item?.severity || "-"}}</td>
                                                <td>{{item?.certainty || "-"}}</td>
                                                <td>{{item?.description || "-"}}</td>

                                                <td><i nz-icon nzType="icons:delete" nzTheme="outline"
                                                        class="remove-btn"
                                                        (click)="(item?.reactionId || item?.id) && removeReaction(item?.reactionId || item?.id?.toString() || '')"></i>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </nz-table>
                        </nz-content>
                    </nz-layout>
                </form>
            </div>

            <div fxLayout="row" fxLayoutAlign="end" class="footer">
                <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
                    [disabled]="isLoading">{{"Save" | translate}}</button>
                <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleCancel()"
                    [disabled]="isLoading">{{"Cancel" | translate}}</button>
            </div>
        </form>
    </ng-container>
    <ng-template [nzModalFooter]>
        <button type="submit">{{"Save" | translate}}</button>
    </ng-template>
</nz-modal>