<nz-layout class="summary-container">
    <nz-header class="section-header" fxLayout="column" fxLayoutAlign="center start">
        <div fxLayout="column" class="patient-name">
            <span>{{data?.patient?.firstName}} {{data?.patient?.lastName}}</span>
        </div>

        <div fxLayout="column" fxLayout="start center" fxLayoutGap="15px" class="patient-details mpt-5">
            <div fxLayout="column">
                <div *ngIf="data?.patient?.gender"><i nz-icon nzType="icons:gender" nzTheme="outline"
                        class="mr-5"></i>{{data?.patient?.gender}}</div>
            </div>
            <div fxLayout="column">
                <div *ngIf="data?.patient?.dateOfBirth"><i nz-icon nzType="icons:calendar-days" nzTheme="outline"
                        class="mr-5"></i>{{data?.patient?.dateOfBirth}}</div>
            </div>

            <div fxLayout="column">
                <div *ngIf="data?.patient?.phone"><i nz-icon nzType="icons:phone" nzTheme="outline"
                        class="mr-5"></i>{{data?.patient?.phone}}</div>
            </div>
            <div fxLayout="column">
                <div *ngIf="data?.patient?.email"><i nz-icon nzType="icons:email" nzTheme="outline"
                        class="mr-5"></i>{{data?.patient?.email}}</div>
            </div>
            <div fxLayout="column">
                <div *ngIf="data?.patient?.address"><i nz-icon nzType="icons:addressInfo" nzTheme="outline"
                        class="mr-5"></i>{{data?.patient?.address}}</div>
            </div>
        </div>


        <div fxLayout="column" fxLayout="start center" fxLayoutGap="15px" class="patient-documents">
            <ng-container *ngFor="let doc of data?.patient?.documents; let i = index">
                <div fxLayout="column" fxLayout="start center" fxLayoutGap="15px" class="patient-details">
                    <div fxLayout="column">
                        <div *ngIf="doc?.documentNumber">
                            {{doc?.documentType}}{{doc?.documentType ? '-' :
                            ''}}{{doc?.documentNumber}}{{doc?.documentCountry ? '-' : ''}}{{doc?.documentCountry}}</div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div fxLayout="column" fxLayout="start center" fxLayoutGap="15px" class="patient-documents">
            <div fxLayout="column" fxLayout="start center" fxLayoutGap="15px" class="patient-details">
                <div fxLayout="column">
                    <div *ngIf="data?.patient?.doctorFullName"><i nz-icon nzType="icons:doctor" nzTheme="outline"
                            class="mr-5"></i>{{data?.patient?.doctorFullName}}</div>
                </div>
                <div fxLayout="column">
                    <div *ngIf="data?.patient?.hospitalName"><i nz-icon nzType="icons:hospital" nzTheme="outline"
                            class="mr-5"></i>{{data?.patient?.hospitalName}}</div>
                </div>
            </div>

            <div fxLayout="column" fxLayout="start center" fxLayoutGap="15px" class="patient-details">
                <div fxLayout="column">
                    <div *ngIf="data?.patient?.hospitalPhoneNumber"><i nz-icon nzType="icons:phone" nzTheme="outline"
                            class="mr-5"></i>{{data?.patient?.hospitalPhoneNumber}}</div>
                </div>
                <div fxLayout="column">
                    <div *ngIf="data?.patient?.hospitalEmail"><i nz-icon nzType="icons:email" nzTheme="outline"
                            class="mr-5"></i>{{data?.patient?.hospitalEmail}}</div>
                </div>
            </div>
        </div>
    </nz-header>
    <nz-content class="summary-body">

        <!-- ALLERGIES -->
        <app-custom-table title="Allergies" [data]="data?.allergies?.getAll" [dataGetList]="data?.allergies"
            [isPreview]="true" [isLoading]="isLoading" [isPreview]="false">
            <ng-container *ngFor="let allergy of data?.allergies?.getAll">

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="info" fxLayout="column" fxLayoutAlign="center start">
                                <div fxLayout="column" class="title">
                                    <span>{{allergy.name}}</span>
                                </div>

                                <div fxLayout="column" fxLayout="start center" fxLayoutGap="15px">
                                    <div fxLayout="column" *ngIf="allergy?.effectiveLow">
                                        <div><span class="f-500">{{"Resolution Date"|translate}}: </span>
                                            {{this.formatDate(allergy.effectiveLow)}}</div>
                                    </div>
                                    <div fxLayout="column">
                                        <div><span class="f-500">{{"Code"|translate}}: </span>{{allergy.code}}</div>
                                    </div>
                                    <div fxLayout="column">
                                        <div><span class="f-500">{{"Code System"|translate}}:
                                            </span>{{allergy.codeSystem}}</div>
                                    </div>
                                    <div fxLayout="column">
                                        <div><span class="f-500">{{"Status"|translate}}:
                                            </span>{{allergy.status}}</div>
                                    </div>
                                </div>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div *ngIf="allergy?.getAllReactions?.length == 0 || !allergy?.getAllReactions">
                        <div class="info" fxLayout="column" fxLayoutAlign="center start">
                            {{"No reactions listed." | translate}}
                        </div>
                    </div>
                    <ng-container *ngFor="let reaction of allergy?.getAllReactions; let i = index">
                        <div class="info" fxLayout="column" fxLayoutAlign="center start">
                            <div fxLayout="column" class="title">
                                <span>{{i + 1}}. {{reaction.name}}</span>
                            </div>

                            <div fxLayout="column" fxLayout="start center" fxLayoutGap="15px">
                                <div fxLayout="column">
                                    <div><span class="f-500">{{"Start Date"|translate}}: </span>
                                        {{this.formatDate(reaction.effectiveLow)}}</div>
                                </div>
                                <div fxLayout="column">
                                    <div><span class="f-500">{{"End Date"|translate}}: </span>
                                        {{this.formatDate(reaction.effectiveHigh)}}</div>
                                </div>
                                <div fxLayout="column">
                                    <div><span class="f-500">{{"Code"|translate}}: </span>{{reaction.code}}</div>
                                </div>
                                <div fxLayout="column">
                                    <div><span class="f-500">{{"Code System"|translate}}:
                                        </span>{{reaction.codeSystem}}</div>
                                </div>
                                <div fxLayout="column">
                                    <div><span class="f-500">{{"Status"|translate}}:
                                        </span>{{reaction.status}}</div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </mat-expansion-panel>

            </ng-container>
        </app-custom-table>

        <!-- CARE PLANS -->
        <app-custom-table *ngIf="data?.carePlans" title="Care Plans" [data]="data?.carePlans" [isPreview]="true"
            [isLoading]="isLoading" [isPreview]="false">
            <ng-container *ngFor="let cp of data?.carePlans">
                <div class="entry-container">
                    <div class="info" fxLayout="column" fxLayoutAlign="center start">
                        <div fxLayout="column" class="title">
                            <span>{{cp.description}}</span>
                        </div>
                        <div fxLayout="column" fxLayout="start center" fxLayoutGap="15px">
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Date"|translate}}: </span>
                                    {{cp.dateAdded}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </app-custom-table>

        <!-- PROBLEMS -->
        <app-custom-table title="Problems" [data]="data?.problems?.getAll" [dataGetList]="data?.problems"
            [isPreview]="true" [isLoading]="isLoading" [isPreview]="false">
            <ng-container *ngFor="let problem of data?.problems?.getAll">
                <div class="entry-container">
                    <div class="info" fxLayout="column" fxLayoutAlign="center start">
                        <div fxLayout="column" class="title">
                            <span>{{problem.name}}</span>
                        </div>
                        <div fxLayout="column" fxLayout="start center" fxLayoutGap="15px">
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Date"|translate}}: </span>
                                    {{this.formatDate(problem.effectiveLow)}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Code"|translate}}: </span>{{problem.code}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Code System"|translate}}:
                                    </span>{{problem.codeSystem}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Status"|translate}}:
                                    </span>{{problem.status}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </app-custom-table>

        <!-- PROCEDURES -->
        <app-custom-table title="Procedures" [data]="data?.procedures?.getAll" [dataGetList]="data?.procedures"
            [isPreview]="true" [isLoading]="isLoading" [isPreview]="false">
            <ng-container *ngFor="let procedure of data?.procedures?.getAll">
                <div class="entry-container">
                    <div class="info" fxLayout="column" fxLayoutAlign="center start">
                        <div fxLayout="column" class="title">
                            <span>{{procedure.name}}</span>
                        </div>
                        <div fxLayout="column" fxLayout="start center" fxLayoutGap="15px">
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Date"|translate}}: </span>
                                    {{this.formatDate(procedure.effectiveLow)}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Code"|translate}}: </span>{{procedure.code}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Code System"|translate}}:
                                    </span>{{procedure.codeSystem}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Status"|translate}}:
                                    </span>{{procedure.status}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </app-custom-table>

        <!-- MEDICATIONS -->
        <app-custom-table title="Medications" [data]="data?.medications?.getAll" [dataGetList]="data?.medications"
            [isPreview]="true" [isLoading]="isLoading" [isPreview]="false">
            <ng-container *ngFor="let medication of data?.medications?.getAll">
                <div class="entry-container">
                    <div class="info" fxLayout="column" fxLayoutAlign="center start">
                        <div fxLayout="column" class="title">
                            <span>{{medication.name}}</span>
                        </div>
                        <div fxLayout="column" fxLayout="start center" fxLayoutGap="15px">
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Date"|translate}}: </span>
                                    {{this.formatDate(medication.effectiveLow)}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Code"|translate}}: </span>{{medication.code}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Code System"|translate}}:
                                    </span>{{medication.codeSystem}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Status"|translate}}:
                                    </span>{{medication.status}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </app-custom-table>

        <!-- MEDICAL DEVICES -->
        <app-custom-table title="Medical Devices" [data]="data?.medicalDevices?.getAll"
            [dataGetList]="data?.medicalDevices" [isPreview]="true" [isLoading]="isLoading" [isPreview]="false">
            <ng-container *ngFor="let md of data?.medicalDevices?.getAll">
                <div class="entry-container">
                    <div class="info" fxLayout="column" fxLayoutAlign="center start">
                        <div fxLayout="column" class="title">
                            <span>{{md.name}}</span>
                        </div>
                        <div fxLayout="column" fxLayout="start center" fxLayoutGap="15px">
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Date"|translate}}: </span>
                                    {{this.formatDate(md.effectiveLow)}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Code"|translate}}: </span>{{md.code}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Code System"|translate}}:
                                    </span>{{md.codeSystem}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Status"|translate}}:
                                    </span>{{md.status}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </app-custom-table>

        <!-- SOCIAL HISTORY -->
        <app-custom-table title="Social History" [data]="data?.socialHistory" [isPreview]="true" [isLoading]="isLoading"
            [isPreview]="false">
            <ng-container *ngFor="let sh of data?.socialHistory">
                <div class=" entry-container">
                    <div class="info" fxLayout="column" fxLayoutAlign="center start">
                        <div fxLayout="column" class="title">
                            <span>{{sh.name}}</span>
                        </div>
                        <div fxLayout="column" fxLayout="start center" fxLayoutGap="15px">
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Date"|translate}}: </span>
                                    {{this.formatDate(sh.effectiveLow)}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Code"|translate}}: </span>{{sh.code}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Code System"|translate}}:
                                    </span>{{sh.codeSystem}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Status"|translate}}:
                                    </span>{{sh.status}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </app-custom-table>

        <!-- VACCINATIONS -->
        <app-custom-table title="Vaccinations" [data]="data?.vaccinations?.getAll" [dataGetList]="data?.vaccinations"
            [isPreview]="true" [isLoading]="isLoading" [isPreview]="false">
            <ng-container *ngFor="let vaccination of data?.vaccinations?.getAll">
                <div class="entry-container">
                    <div class="info" fxLayout="column" fxLayoutAlign="center start">
                        <div fxLayout="column" class="title">
                            <span>{{vaccination.name}}</span>
                        </div>
                        <div fxLayout="column" fxLayout="start center" fxLayoutGap="15px">
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Date"|translate}}: </span>
                                    {{this.formatDate(vaccination.effectiveLow)}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Code"|translate}}: </span>{{vaccination.code}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Code System"|translate}}:
                                    </span>{{vaccination.codeSystem}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Status"|translate}}:
                                    </span>{{vaccination.status}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </app-custom-table>

        <!-- PREGNANCY STATUS -->
        <app-custom-table *ngIf="data?.pregnancyModels?.statusData" title="Pregnancy Status"
            [data]="data?.pregnancyModels?.statusData" [isPreview]="true" [isLoading]="isLoading" [isPreview]="false">
            <ng-container *ngFor="let ps of data?.pregnancyModels?.statusData">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="info" fxLayout="column" fxLayoutAlign="center start">
                                <div fxLayout="column" class="title">
                                    <span>{{ps.name}}</span>
                                </div>
                                <div fxLayout="column" fxLayout="start center" fxLayoutGap="15px">
                                    <div fxLayout="column">
                                        <div><span class="f-500">{{"Date"|translate}}: </span>
                                            {{this.formatDate(ps.date)}}</div>
                                    </div>
                                    <div fxLayout="column">
                                        <div><span class="f-500">{{"Code"|translate}}: </span>{{ps.code}}</div>
                                    </div>
                                    <div fxLayout="column">
                                        <div><span class="f-500">{{"Code System"|translate}}:
                                            </span>{{ps.codeSystem}}</div>
                                    </div>
                                    <div fxLayout="column">
                                        <div><span class="f-500">{{"Status"|translate}}:
                                            </span>{{ps.status}}</div>
                                    </div>
                                </div>
                            </div>

                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="info" fxLayout="column" fxLayoutAlign="center start">
                        <div fxLayout="column" class="title">
                            <span>{{ps.pregnancyStatusEntryRealtionship.name}}</span>
                        </div>
                        <div fxLayout="column" fxLayout="start center" fxLayoutGap="15px">
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Date"|translate}}: </span>
                                    {{this.formatDate(ps.pregnancyStatusEntryRealtionship.date)}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Code"|translate}}:
                                    </span>{{ps.pregnancyStatusEntryRealtionship.code}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Code System"|translate}}:
                                    </span>{{ps.pregnancyStatusEntryRealtionship.codeSystem}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Status"|translate}}:
                                    </span>{{ps.pregnancyStatusEntryRealtionship.status}}</div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>

            </ng-container>
        </app-custom-table>

        <!-- PREGNANCY OUTCOME -->
        <app-custom-table *ngIf="data?.pregnancyModels?.outcomeData" title="Pregnancy Outcome"
            [data]="data?.pregnancyModels?.outcomeData" [isPreview]="true" [isLoading]="isLoading" [isPreview]="false">
            <ng-container *ngFor="let po of data?.pregnancyModels?.outcomeData">
                <div class="entry-container">
                    <div class="info" fxLayout="column" fxLayoutAlign="center start">
                        <div fxLayout="column" class="title">
                            <span>{{po.name}}</span>
                        </div>
                        <div fxLayout="column" fxLayout="start center" fxLayoutGap="15px">
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Value"|translate}}: </span>
                                    {{po.value}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Code"|translate}}: </span>{{po.code}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Code System"|translate}}:
                                    </span>{{po.codeSystem}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Status"|translate}}:
                                    </span>{{po.status}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </app-custom-table>

        <!-- PREGNANCY HISTORY -->
        <app-custom-table *ngIf="data?.pregnancyModels?.historyData" title="Pregnancy History"
            [data]="data?.pregnancyModels?.historyData" [isPreview]="true" [isLoading]="isLoading" [isPreview]="false">
            <ng-container *ngFor="let ph of data?.pregnancyModels?.historyData">
                <div class="entry-container">
                    <div class="info" fxLayout="column" fxLayoutAlign="center start">
                        <div fxLayout="column" class="title">
                            <span>{{ph.code}}</span>
                        </div>
                        <div fxLayout="column" fxLayout="start center" fxLayoutGap="15px">
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Value"|translate}}: </span>
                                    {{ph.value}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Code"|translate}}: </span>{{ph.code}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Code System"|translate}}:
                                    </span>{{ph.codeSystem}}</div>
                            </div>
                            <div fxLayout="column">
                                <div><span class="f-500">{{"Status"|translate}}:
                                    </span>{{ph.status}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </app-custom-table>

    </nz-content>
</nz-layout>

<nz-modal #modalRef nzSize [(nzVisible)]="isVisible" [nzTitle]="'Demographics' | translate"
    (nzOnCancel)="handleCancel()" nzOkType="primary" [nzFooter]="null">

</nz-modal>