<div *ngIf="!loading" class="new-user-container">
  <h2 class="titleBar">{{ "Create New User" | translate }}</h2>

  <form [formGroup]="personalInfoForm" (ngSubmit)="createUser()">
    <mat-vertical-stepper #stepper>
      <mat-step [label]="'1. ' + ('User Information' | translate)">
        <div class="row-fields">
          <!-- External doctor lookup -->
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            class="add-patient-row"
            style="gap: 16px; margin-left: 14px"
          >
            <label>{{ "Is External Doctor?" | translate }}</label>
            <nz-switch
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="isExternalDoctor"
              (ngModelChange)="onExternalDoctorToggle($event)"
            ></nz-switch>

            <nz-select
              *ngIf="isExternalDoctor"
              [(ngModel)]="selectedExternalDoctor"
              [ngModelOptions]="{ standalone: true }"
              (ngModelChange)="onExternalDoctorSelected($event)"
              [nzPlaceHolder]="'Select External Doctor' | translate"
              style="width: 300px"
            >
              <nz-option
                *ngFor="let doctor of externalDoctors"
                [nzLabel]="doctor.name + ' ' + doctor.surname"
                [nzValue]="doctor"
              ></nz-option>
            </nz-select>
          </div>
          <!-- Gap -->
          <div fxLayout="row" class="add-patient-row">
            <div fxLayout="col" fxFlex="50" class="add-patient-col"></div>
          </div>
          <!-- First Name and Last Name Fields -->
          <div>
            <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                {{ "First name" | translate }}
                <span class="required-asterisk">*</span>
              </div>
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                {{ "Last name" | translate }}
                <span class="required-asterisk">*</span>
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              class="add-patient-row"
            >
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                <input
                  class="add-patient-input"
                  [placeholder]="'First name' | translate"
                  formControlName="firstName"
                />
              </div>
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                <input
                  class="add-patient-input"
                  [placeholder]="'Last name' | translate"
                  formControlName="lastName"
                />
              </div>
            </div>
            <!-- Error messages for First Name and Last Name in separate row -->
            <div fxLayout="row" class="add-patient-row">
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                <div
                  *ngIf="
                    personalInfoForm.get('firstName')?.touched &&
                    personalInfoForm.get('firstName')?.invalid
                  "
                  class="error-message"
                >
                  <div
                    *ngIf="personalInfoForm.get('firstName')?.errors?.['required']"
                  >
                    {{ "First name is required" | translate }}
                  </div>
                </div>
              </div>
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                <div
                  *ngIf="
                    personalInfoForm.get('lastName')?.touched &&
                    personalInfoForm.get('lastName')?.invalid
                  "
                  class="error-message"
                >
                  <div
                    *ngIf="personalInfoForm.get('lastName')?.errors?.['required']"
                  >
                    {{ "Last name is required" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Username Field -->
          <div>
            <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
              <div fxLayout="col" fxFlex="100" class="add-patient-col">
                {{ "Username" | translate }}
                <span class="required-asterisk">*</span>
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              class="add-patient-row"
            >
              <div fxLayout="col" fxFlex="100" class="add-patient-col">
                <input
                  class="add-patient-input"
                  [placeholder]="'Username' | translate"
                  formControlName="username"
                />
              </div>
            </div>
            <!-- Error message for Username in a separate row -->
            <div fxLayout="row" class="add-patient-row">
              <div fxLayout="col" fxFlex="100" class="add-patient-col">
                <div
                  *ngIf="
                    personalInfoForm.get('username')?.touched &&
                    personalInfoForm.get('username')?.invalid
                  "
                  class="error-message"
                >
                  <div
                    *ngIf="personalInfoForm.get('username')?.errors?.['required']"
                  >
                    {{ "Username is required" | translate }}
                  </div>
                  <div
                    *ngIf="personalInfoForm.get('username')?.errors?.['pattern']"
                  >
                    {{
                      "Username must be 6-20 characters long and can contain only Latin letters,numbers and the dot symbol (.)"
                        | translate
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Temporary Password and Confirm Temporary Password Fields -->

          <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
            <div fxLayout="col" fxFlex="50" class="add-patient-col">
              {{ "Temporary Password" | translate }}
              <span class="required-asterisk">*</span>
            </div>
            <div fxLayout="col" fxFlex="50" class="add-patient-col">
              {{ "Confirm Temporary Password" | translate }}
              <span class="required-asterisk">*</span>
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            class="add-patient-row password-row"
          >
            <div fxLayout="col" fxFlex="50" class="add-patient-col">
              <nz-input-group
                [ngClass]="{
                  'error-border':
                    personalInfoForm.get('tempPassword')?.invalid &&
                    personalInfoForm.get('tempPassword')?.touched
                }"
                [nzSuffix]="tempPasswordSuffixTemplate"
              >
                <input
                  [type]="hideTempPassword ? 'password' : 'text'"
                  nz-input
                  class="passwordInput"
                  [placeholder]="'Temporary Password' | translate"
                  formControlName="tempPassword"
                />
              </nz-input-group>
            </div>
            <div fxLayout="col" fxFlex="50" class="add-patient-col">
              <nz-input-group
                [ngClass]="{
                  'error-border':
                    personalInfoForm.get('confirmTempPassword')?.invalid &&
                    personalInfoForm.get('confirmTempPassword')?.touched
                }"
                [nzSuffix]="confirmPasswordSuffixTemplate"
              >
                <input
                  [type]="hideConfirmPassword ? 'password' : 'text'"
                  nz-input
                  class="passwordInput"
                  [placeholder]="'Confirm Temporary Password' | translate"
                  formControlName="confirmTempPassword"
                  [disabled]="!personalInfoForm.get('tempPassword')?.valid"
                />
              </nz-input-group>
            </div>
          </div>
          <ng-template #tempPasswordSuffixTemplate>
            <span
              nz-icon
              [nzType]="hideTempPassword ? 'eye-invisible' : 'eye'"
              (click)="togglePasswordVisibility('tempPassword')"
            ></span>
          </ng-template>
          <ng-template #confirmPasswordSuffixTemplate>
            <span
              nz-icon
              [nzType]="hideConfirmPassword ? 'eye-invisible' : 'eye'"
              (click)="togglePasswordVisibility('confirmPassword')"
            ></span>
          </ng-template>
          <!-- Password validation messages -->
          <div fxLayout="row" class="add-patient-row">
            <div fxLayout="col" fxFlex="50" class="add-patient-col">
              <div class="password-criteria">
                <div>
                  <mat-icon
                    [ngClass]="{
                      valid: passwordCriteria.minLength,
                      invalid: !passwordCriteria.minLength
                    }"
                  >
                    {{ passwordCriteria.minLength ? "check_circle" : "cancel" }}
                  </mat-icon>
                  {{ "At least 8 characters" | translate }}
                </div>
                <div>
                  <mat-icon
                    [ngClass]="{
                      valid: passwordCriteria.upperLowerCase,
                      invalid: !passwordCriteria.upperLowerCase
                    }"
                  >
                    {{
                      passwordCriteria.upperLowerCase
                        ? "check_circle"
                        : "cancel"
                    }}
                  </mat-icon>
                  {{ "Contains uppercase and lowercase letters" | translate }}
                </div>
                <div>
                  <mat-icon
                    [ngClass]="{
                      valid: passwordCriteria.digits,
                      invalid: !passwordCriteria.digits
                    }"
                  >
                    {{ passwordCriteria.digits ? "check_circle" : "cancel" }}
                  </mat-icon>
                  {{ "Contains digits" | translate }}
                </div>
                <div>
                  <mat-icon
                    [ngClass]="{
                      valid: passwordCriteria.specialChars,
                      invalid: !passwordCriteria.specialChars
                    }"
                  >
                    {{
                      passwordCriteria.specialChars ? "check_circle" : "cancel"
                    }}
                  </mat-icon>
                  {{ "Contains special characters" | translate }}
                </div>
              </div>
            </div>
            <div fxLayout="col" fxFlex="50" class="add-patient-col">
              <div
                *ngIf="
                  personalInfoForm.get('confirmTempPassword')?.touched &&
                  personalInfoForm.get('confirmTempPassword')?.invalid
                "
                class="error-message"
              >
                <div
                  *ngIf="personalInfoForm.get('confirmTempPassword')?.errors?.['passwordMismatch']"
                >
                  {{ "Passwords do not match" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Navigation Buttons -->
        <div fxLayout="row" fxLayoutAlign="end center">
          <button
            class="add-patient-button"
            [disabled]="!personalInfoForm.valid"
            (click)="nextStep(stepper, 1)"
            type="button"
          >
            {{ "Next" | translate }}
          </button>
        </div>
      </mat-step>

      <mat-step
        [label]="'2. ' + ('Contact Information' | translate)"
        [formGroup]="contactInfoForm"
      >
        <div class="row-fields">
          <div>
            <div fxLayout="row" fxLayoutAlign="start" class="label-row">
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                {{ "Email" | translate }}
                <span class="required-asterisk">*</span>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start" class="add-patient-row">
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                <input
                  id="email"
                  class="add-patient-input"
                  [placeholder]="'Email' | translate"
                  formControlName="email"
                />
              </div>
            </div>
          </div>

          <div>
            <div fxLayout="row" fxLayoutAlign="start" class="label-row">
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                {{ "Phone Number" | translate }}
                <span class="required-asterisk">*</span>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start" class="add-patient-row">
              <div fxLayout="col" fxFlex="50" class="add-patient-col">
                <ngx-intl-tel-input
                  [cssClass]="'custom'"
                  [preferredCountries]="[CountryISO.Cyprus, CountryISO.Greece]"
                  [enableAutoCountrySelect]="false"
                  [enablePlaceholder]="false"
                  [searchCountryFlag]="true"
                  [searchCountryField]="[
                    SearchCountryField.Iso2,
                    SearchCountryField.Name
                  ]"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.Cyprus"
                  [maxLength]="15"
                  [phoneValidation]="true"
                  [separateDialCode]="true"
                  name="phone"
                  formControlName="phoneNumber"
                ></ngx-intl-tel-input>
              </div>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center">
          <button
            class="back-button"
            (click)="nextStep(stepper, -1)"
            type="button"
          >
            Back
          </button>
          <button
            class="add-patient-button btn-margin"
            (click)="nextStep(stepper, 1)"
            type="button"
          >
            {{ "Next" | translate }}
          </button>
        </div>
      </mat-step>

      <mat-step
        [label]="'3. ' + ('Roles and Language' | translate)"
        [formGroup]="rolesAndLanguageForm"
      >
        <div class="row-fields">
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            class="add-patient-row"
          >
            <div fxLayout="col" fxFlex="50" class="multiselector">
              <nz-select
                nzMode="multiple"
                class=""
                style="width: 100%"
                formControlName="roles"
                nzPlaceHolder="{{ 'Roles' | translate }}"
              >
                <option disabled selected>{{ "Role" | translate }}</option>
                <ng-container *ngFor="let role of roles">
                  <nz-option
                    [nzLabel]="role.translatedName"
                    [nzValue]="role.id"
                  ></nz-option>
                </ng-container>
              </nz-select>
            </div>

            <div fxLayout="col" fxFlex="50" class="add-patient-col">
              <select class="add-patient-input" formControlName="locale">
                <option value="" disabled selected>
                  {{ "Language" | translate }}
                </option>
                <ng-container *ngFor="let locale of locales">
                  <option [value]="locale.id">{{ locale.name }}</option>
                </ng-container>
              </select>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center">
          <button class="cancel-button" (click)="cancelCreate()">
            {{ "Cancel" | translate }}
          </button>
          <button
            class="back-button btn-margin"
            (click)="nextStep(stepper, -1)"
            type="button"
          >
            Back
          </button>
          <button
            class="add-patient-button btn-margin"
            [class.disabled-button]="!rolesAndLanguageForm.valid"
            [disabled]="!rolesAndLanguageForm.valid"
            type="submit"
          >
            {{ "Create User" | translate }}
          </button>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </form>
</div>

<div *ngIf="loading" class="loading-container">
  <!-- Centered loading spinner -->
  <div class="loading-text">
    <p>{{ "Loading..." | translate }}</p>
  </div>
  <mat-spinner></mat-spinner>
</div>
