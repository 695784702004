import { Component, Input } from '@angular/core';
import { IModule } from 'src/app/interfaces/IModule';
import { paths } from 'src/component-paths/component-paths';

@Component({
  selector: 'app-assessment-section',
  templateUrl: './assessment-section.component.html',
  styleUrls: ['./assessment-section.component.css'],
})
export class AssessmentSectionComponent {
  @Input() submenu?: IModule;
  componentPaths = paths;
}
