<div *ngIf="!data" class="full-screen-container">
    <img src="../../../assets/images/loginScreen/loginBuilding.png" class="background-image">

    <div class="content-container" fxLayout="column" fxLayoutAlign="center center">
        <div class="content-column" fxLayout="column" fxFlex="60">
            <div fxLayout="row" class="w-100 forgot-line" fxLayoutAlign="center center">
                <img src="../../../assets/images/loginScreen/3aHealth.png" class="logo">
            </div>

            <div fxLayout="row" class="w-100 login-text" fxLayoutAlign="start start">
                {{"Smart Health Link" | translate}}
            </div>

            <div fxLayout="row" class="w-100 patient-text" fxLayoutAlign="start start">
                {{"Please enter the passcode given by the patient to access their medical information." | translate}}
            </div>

            <div class="password-input-container" style="position: relative; width: 100%;">
                <input #passcodeInput nz-input [(ngModel)]="password" [type]="passwordFieldType" class="password"
                    placeholder="Passcode" (keydown.enter)="enterPasscode()" />
                <i class="fa" [ngClass]="passwordFieldType === 'password' ? 'fa-eye' : 'fa-eye-slash'"
                    (click)="togglePasswordVisibility()"></i>
            </div>

            <div class="error-text">{{errorMsg}}</div>

            <button nz-button [ngClass]="{'login-btn-disabled': isLoading, 'login-btn': !isLoading}"
                (click)="enterPasscode()" [disabled]="isLoading">{{"View" |
                translate}}</button>
        </div>
    </div>
</div>

<div *ngIf="data">
    <app-shl-patient-summary [data]="data" [isLoading]="isLoading" />
</div>