import {
  Component,
  Input,
  SimpleChanges,
  OnInit,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  FormControl,
} from '@angular/forms';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input-gg';
import {
  IAdmissionEmergencyContactsInfo,
  EmergencyContact,
} from 'src/app/interfaces/AdmissionPatient/AdmissionProfile/IAdmissionEmergencyContactsInfo';
import { ICoding } from 'src/app/interfaces/ICoding';

@Component({
  selector: 'app-admission-emergency-contacts-info',
  templateUrl: './admission-emergency-contacts-info.component.html',
  styleUrls: ['./admission-emergency-contacts-info.component.css'],
})
export class AdmissionEmergencyContactsInfoComponent
  implements OnInit, OnChanges
{
  @Input() admissionEmergencyContactsInfoData?: IAdmissionEmergencyContactsInfo;
  @Input() closestRelativesList: ICoding[] = [];
  @Input() formSubmitted?: boolean = false;
  @Input() countriesList: ICoding[] = [];
  @Input() documentTypeList: ICoding[] = [];
  @Input() isEditMode: boolean = false;

  emergencyContactsInfoForm: FormGroup;
  currentPage: number = 0;
  isCountryCyprus: boolean = false;

  // Define the Cyprus cities
  cyprusCities: string[] = [
    'Nicosia',
    'Limassol',
    'Larnaca',
    'Paphos',
    'Famagusta',
    'Kyrenia',
  ];

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Cyprus, CountryISO.Greece];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.emergencyContactsInfoForm = this.formBuilder.group({
      contacts: this.formBuilder.array([]),
    });
  }

  ngOnInit() {
    if (this.admissionEmergencyContactsInfoData) {
      this.setContacts(this.admissionEmergencyContactsInfoData.contacts || []);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['admissionEmergencyContactsInfoData'] &&
      this.admissionEmergencyContactsInfoData
    ) {
      this.setContacts(this.admissionEmergencyContactsInfoData.contacts || []);
    }
    if (changes['isEditMode']) {
      this.setFormControlsState();
    }
  }

  get contacts(): FormArray {
    return this.emergencyContactsInfoForm.get('contacts') as FormArray;
  }

  // Access a specific contact FormGroup
  getContactFormGroup(index: number): AbstractControl {
    return this.contacts.at(index);
  }

  setContacts(contacts: EmergencyContact[]) {
    const contactFGs = contacts.map((contact) =>
      this.formBuilder.group({
        id: [{ value: contact.id }],
        firstName: [
          { value: contact.firstName, disabled: !this.isEditMode },
          Validators.required,
        ],
        lastName: [
          { value: contact.lastName, disabled: !this.isEditMode },
          Validators.required,
        ],
        closestRelativeId: [
          { value: contact.closestRelativeId, disabled: !this.isEditMode },
        ],
        closestRelativeOther: [
          {
            value: contact.closestRelativeOther || '',
            disabled: !this.isEditMode,
          },
        ],
        occupation: [{ value: contact.occupation, disabled: !this.isEditMode }],
        phoneNumber: [
          { value: contact.phoneNumber || '', disabled: !this.isEditMode },
          Validators.required,
        ],
        phoneMockControl: [
          { value: contact.phoneNumber || '', disabled: !this.isEditMode },
          Validators.required,
        ],
        email: [
          { value: contact.email, disabled: !this.isEditMode },
          Validators.email,
        ],
        documentTypeId: [
          { value: contact.documentTypeId, disabled: !this.isEditMode },
        ],
        documentNumber: [
          { value: contact.documentNumber, disabled: !this.isEditMode },
        ],
        documentCountryIssuedId: [
          {
            value: contact.documentCountryIssuedId,
            disabled: !this.isEditMode,
          },
        ],
        addressData: this.formBuilder.group({
          street: [
            {
              value: contact.addressData?.street || undefined,
              disabled: !this.isEditMode,
            },
          ],
          streetNumber: [
            {
              value: contact.addressData?.streetNumber || undefined,
              disabled: !this.isEditMode,
            },
          ],
          apartmentNumber: [
            {
              value: contact.addressData?.apartmentNumber || undefined,
              disabled: !this.isEditMode,
            },
          ],
          postCode: [
            {
              value: contact.addressData?.postCode || undefined,
              disabled: !this.isEditMode,
            },
          ],
          district: [
            {
              value: contact.addressData?.district || undefined,
              disabled: !this.isEditMode,
            },
          ],
          town: [
            {
              value: contact.addressData?.town || undefined,
              disabled: !this.isEditMode,
            },
          ],
          countryId: [
            {
              value: contact.addressData?.countryId || undefined,
              disabled: !this.isEditMode,
            },
          ],
        }),
      })
    );
    const contactFormArray = this.formBuilder.array(contactFGs);
    this.emergencyContactsInfoForm.setControl('contacts', contactFormArray);
    this.updateValidators();
  }

  setFormControlsState() {
    this.contacts.controls.forEach((control: AbstractControl) => {
      if (this.isEditMode) {
        control.enable();
      } else {
        control.disable();
      }
    });
  }

  updateValidators() {
    this.contacts.controls.forEach((contact, index) => {
      if (index === 0) {
        contact.get('documentTypeId')?.setValidators(Validators.required);
        contact.get('documentNumber')?.setValidators(Validators.required);
        contact
          .get('documentCountryIssuedId')
          ?.setValidators(Validators.required);
      } else {
        contact.get('documentTypeId')?.clearValidators();
        contact.get('documentNumber')?.clearValidators();
        contact.get('documentCountryIssuedId')?.clearValidators();
      }
      contact.get('documentTypeId')?.updateValueAndValidity();
      contact.get('documentNumber')?.updateValueAndValidity();
      contact.get('documentCountryIssuedId')?.updateValueAndValidity();
    });
  }

  get totalPages(): number {
    return this.contacts?.length || 0;
  }

  nextPage() {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage++;
    }
  }

  previousPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
    }
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  // Method to handle place of birth change
  onCountryChange(placeId: number): void {
    this.isCountryCyprus = placeId === 55; // Cyprus id is 55
  }

  getPhoneNumberControl(i: number): FormControl<string | null> {
    return (
      (this.getContactFormGroup(i).get('phoneNumber') as FormControl<
        string | null
      >) || new FormControl<string | null>(null)
    );
  }
  // Update the phone number manually on change
  onPhoneNumberChange(phoneNumber: any, index: number) {
    const control = this.contacts.at(index).get('phoneNumber');
    if (control) {
      control.setValue(phoneNumber ? phoneNumber.e164Number : '');
    }
  }

  addNewEmergencyContact() {
    const newContactForm = this.formBuilder.group({
      id: [null],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      closestRelativeId: [null],
      closestRelativeOther: [''],
      occupation: [''],
      phoneNumber: ['', Validators.required],
      phoneMockControl: ['', Validators.required],
      email: ['', Validators.email],
      documentTypeId: [null],
      documentNumber: [''],
      documentCountryIssuedId: [null],
      addressData: this.formBuilder.group({
        street: [''],
        streetNumber: [''],
        apartmentNumber: [''],
        postCode: [''],
        district: [''],
        town: [''],
        countryId: [null],
      }),
    });

    this.contacts.push(newContactForm);
    this.updateValidators(); // Ensure validators are updated for the new form
    this.currentPage = this.totalPages - 1;
    this.cdr.detectChanges();
  }

  resetContacts() {
    const contactFormArray = this.contacts;
    while (contactFormArray.length !== 0) {
      contactFormArray.removeAt(0);
    }
    this.updateValidators();
    this.emergencyContactsInfoForm.reset();

    // Reinitialize contacts
    this.setContacts(this.admissionEmergencyContactsInfoData?.contacts || []);
    this.currentPage = 0;
    // Trigger change detection
    this.cdr.detectChanges();
  }
}
