import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICDADetailed } from 'src/app/interfaces/SHL/ICDADetailed';
import { SHLService } from 'src/app/services/shl.service';

@Component({
  selector: 'app-smart-health-links',
  templateUrl: './smart-health-links.component.html',
  styleUrl: './smart-health-links.component.css',
})
export class SmartHealthLinksComponent {
  @ViewChild('passcodeInput', { static: false })
  passcodeInput?: ElementRef<HTMLInputElement>;

  data?: ICDADetailed;
  isLoading: boolean = false;

  shl: string = '';
  password: string = '';

  passwordFieldType: string = 'password';

  errorMsg: string = '';

  togglePasswordVisibility(): void {
    this.passwordFieldType =
      this.passwordFieldType === 'password' ? 'text' : 'password';
  }

  ngAfterViewInit() {
    if (this.passcodeInput) {
      this.passcodeInput?.nativeElement?.focus();
    }
  }

  constructor(
    private route: ActivatedRoute,
    private readonly shlService: SHLService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.shl = params.get('shl') || '';
    });
  }

  enterPasscode() {
    this.isLoading = true;
    this.shlService.passcode(this.shl, this.password).subscribe({
      next: (response) => {
        if (response.data.files?.at(0)?.location) {
          this.shlService
            .getSHL(response.data.files?.at(0)?.location!)
            .subscribe((res) => {
              this.data = res?.data;
              this.isLoading = false;
            });
        }
      },
      error: (error) => {
        this.errorMsg = error.error.message;

        this.isLoading = false;
      },
    });
  }
}
