<div
  [visible]="isUpdating && showPatientInformation"
  fxLayout="column"
  class="spinner"
>
  <h2>Updating patient...</h2>
  <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
</div>

<nz-layout
  [visible]="!isUpdating && showPatientInformation"
  class="full-height"
>
  <nz-header>
    <div class="header-content">
      <div class="header-title">
        <!-- <span
          *ngIf="canNavigateBackInput"
          class="custom-button"
          (click)="previousScreen()"
        >
          <mat-icon class="information-icon">keyboard_arrow_left</mat-icon>
        </span>-->
        {{
          canNavigateBackInput
            ? "Patient's Information"
            : ("Patient's Admission Profile" | translate)
        }}

        <span
          *ngIf="
            admissionPersonalInfoData?.firstName &&
            admissionPersonalInfoData?.lastName
          "
        >
          {{ canNavigateBackInput ? "" : " : " }}
          {{ canNavigateBackInput ? "" : admissionPersonalInfoData?.firstName }}
          {{
            canNavigateBackInput ? "" : admissionPersonalInfoData?.lastName
          }}</span
        >
        <button
          *ngIf="!isEditMode"
          nz-button
          class="admission-edit-button"
          (click)="enterEditMode()"
        >
          <i nz-icon nzType="icons:userEdit" class="custom-icon"></i>
          <span class="btn-text">
            {{ "Edit" | translate }}
          </span>
        </button>
        <button
          *ngIf="isEditMode && canNavigateBackInput"
          nz-button
          class="admission-cancel-button"
          (click)="requestCancel()"
        >
          <i nz-icon nzType="stop"></i>
          <span class="btn-text">
            {{ "Cancel" | translate }}
          </span>
        </button>
        <button
          *ngIf="isEditMode && canNavigateBackInput"
          nz-button
          class="admission-edit-button"
          (click)="updatePatientInfo()"
        >
          <i nz-icon nzType="check"></i>
          <span class="btn-text">
            {{ "Update" | translate }}
          </span>
        </button>
      </div>

      <div class="header-buttons">
        <button
          *ngIf="isEditMode && !canNavigateBackInput"
          nz-button
          class="admission-edit-button"
          (click)="updatePatientInfo()"
        >
          <i nz-icon nzType="check"></i>
          <span class="btn-text">
            {{ "Update" | translate }}
          </span>
        </button>
        <button
          *ngIf="isEditMode && !canNavigateBackInput"
          nz-button
          class="admission-cancel-button"
          (click)="requestCancel()"
        >
          <i nz-icon nzType="stop"></i>
          <span class="btn-text">
            {{ "Cancel" | translate }}
          </span>
        </button>

        <button
          *ngIf="
            patientInfo?.registrationStatusId == 4 &&
            !isEditMode &&
            !canNavigateBackInput
          "
          (click)="waitlistPatient()"
          nz-button
          class="admission-waitinglist-button"
        >
          <i nz-icon nzType="icons:admissionWaitingList"></i>
          <span class="btn-text">
            {{ "Waiting List" | translate }}
          </span>
        </button>
        <button
          *ngIf="
            patientInfo?.registrationStatusId == 3 &&
            !isEditMode &&
            !canNavigateBackInput
          "
          (click)="openAdmitModal()"
          nz-button
          class="admission-accept-button"
        >
          <i nz-icon nzType="icons:admissionAccept"></i>
          <span class="btn-text">
            {{ "Admit" | translate }}
          </span>
        </button>

        <button
          nz-button
          *ngIf="
            patientInfo?.registrationStatusId != 5 &&
            patientInfo?.registrationStatusId != 2 &&
            !isEditMode &&
            isLoading == 3 &&
            !canNavigateBackInput
          "
          (click)="openRejectModal()"
          class="admission-reject-button"
        >
          <i nz-icon nzType="icons:admissionReject"></i>
          <span class="btn-text">
            {{ "Reject" | translate }}
          </span>
        </button>
      </div>
    </div>
  </nz-header>
  <nz-content>
    <nz-tabset nzType="card">
      <nz-tab [nzTitle]="titleTemplate1">
        <ng-template #titleTemplate1>
          <i nz-icon nzType="icons:personalInfo"></i>
          {{ "Personal Information" | translate }}
        </ng-template>
        <div>
          <app-admission-personal-info
            [admissionPersonalInfoData]="admissionPersonalInfoData"
            [gendersList]="gendersList"
            [bloodTypesList]="bloodTypesList"
            [doctors]="doctors"
            [placeOfBirthList]="placeOfBirthList"
            [closestRelativesList]="closestRelativesList"
            [educationLevelList]="educationLevelList"
            [familyStatusList]="familyStatusList"
            [sourceOfIncomeList]="sourceOfIncomeList"
            [religionList]="religionList"
            [formSubmitted]="formSubmitted"
            [registrationStatusDropdown]="
              admissionDropdownData.registrationStatus!
            "
            [documentTypeList]="documentTypeList"
            [countriesList]="countriesList"
            [avatarImageUrl]="avatarImageUrl"
            [isEditMode]="isEditMode"
          ></app-admission-personal-info>
        </div>
      </nz-tab>

      <nz-tab [nzTitle]="titleTemplate2">
        <ng-template #titleTemplate2>
          <i nz-icon nzType="icons:medicalInfo"></i>
          {{ "Medical Info" | translate }}
        </ng-template>
        <app-admission-medical-info
          [admissionMedicalInfoData]="admissionMedicalInfoData"
          [bloodTypesList]="bloodTypesList"
          [doctors]="doctors"
          [placeOfBirthList]="placeOfBirthList"
          [closestRelativesList]="closestRelativesList"
          [educationLevelList]="educationLevelList"
          [familyStatusList]="familyStatusList"
          [sourceOfIncomeList]="sourceOfIncomeList"
          [religionList]="religionList"
          [immobilityList]="immobilityList"
          [formSubmitted]="formSubmitted"
          [isEditMode]="isEditMode"
        ></app-admission-medical-info>
      </nz-tab>

      <nz-tab [nzTitle]="titleTemplate3">
        <ng-template #titleTemplate3>
          <i nz-icon nzType="icons:addressInfo"></i>
          {{ "Address Info" | translate }}
        </ng-template>
        <app-admission-address-info
          [admissionAddressInfoData]="admissionAddressInfoData"
          [gendersList]="gendersList"
          [bloodTypesList]="bloodTypesList"
          [doctors]="doctors"
          [placeOfBirthList]="placeOfBirthList"
          [closestRelativesList]="closestRelativesList"
          [educationLevelList]="educationLevelList"
          [familyStatusList]="familyStatusList"
          [sourceOfIncomeList]="sourceOfIncomeList"
          [religionList]="religionList"
          [countriesList]="countriesList"
          [formSubmitted]="formSubmitted"
          [isEditMode]="isEditMode"
        ></app-admission-address-info>
      </nz-tab>

      <nz-tab [nzTitle]="titleTemplate4">
        <ng-template #titleTemplate4>
          <i nz-icon nzType="icons:emergencyContacts"></i>
          {{ "Emergency Contacts" | translate }}
        </ng-template>
        <app-admission-emergency-contacts-info
          [countriesList]="countriesList"
          [documentTypeList]="documentTypeList"
          [admissionEmergencyContactsInfoData]="
            admissionEmergencyContactsInfoData
          "
          [closestRelativesList]="closestRelativesList"
          [formSubmitted]="formSubmitted"
          [isEditMode]="isEditMode"
        ></app-admission-emergency-contacts-info>
      </nz-tab>

      <nz-tab [nzTitle]="titleTemplate5">
        <ng-template #titleTemplate5>
          <i nz-icon nzType="icons:insurance"></i>
          {{ "Insurance" | translate }}
        </ng-template>
        <app-admission-insurance
          [admissionInsuranceInfoData]="admissionInsuranceInfoData"
          [insurances]="insuranceList"
          [formSubmitted]="formSubmitted"
          [isEditMode]="isEditMode"
        >
        </app-admission-insurance>
      </nz-tab>

      <nz-tab
        [nzTitle]="titleTemplate6"
        *ngIf="isPatientInfoLoaded && isNotPending() && isNotRejected()"
      >
        <ng-template #titleTemplate6>
          <i nz-icon nzType="icons:documents"></i>
          {{ "Documents" | translate }}
        </ng-template>
        <app-admission-documents
          [patiendIdInput]="patientIdInput ?? null"
          [fileList]="fileList"
          [isEditMode]="isEditMode"
        />

        <!-- <app-admission-documents /> -->
      </nz-tab>
    </nz-tabset>
  </nz-content>
</nz-layout>
