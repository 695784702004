import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { IModule } from 'src/app/interfaces/IModule';
import { paths } from 'src/component-paths/component-paths';
import { debounceTime } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';
import { AllergyService } from 'src/app/services/allergy.service';
import { MedicalAlertService } from 'src/app/services/medicalAlert.service';

@Component({
  selector: 'app-alerts-section',
  templateUrl: './alerts-section.component.html',
  styleUrls: ['./alerts-section.component.css'],
})
export class AlertsSectionComponent {
  @Input() submenu?: IModule[];
  componentPaths = paths;

  @Input() selectedOption?: string;
  @Output() selectedOptionChange = new EventEmitter<string>();

  ngOnInit() {}

  private scrollSubject = new Subject<Event>();

  ngAfterViewInit() {
    setTimeout(() => {
      combineLatest([
        this.allergyService.allergyLoadingSub$,
        this.medicalAlertService.medicalAlertLoadingSub$,
      ]).subscribe(([value1, value2]) => {
        const sectionPath = this.submenu?.find(
          (sm) => sm.path == this.selectedOption
        )?.path;

        if (!value1 && !value2 && sectionPath) {
          const element = document.getElementById(sectionPath);
          if (element) {
            // menu, patient header, title, margin
            const offset = element.offsetTop - 120 - 66 - 64 - 16;
            window.scroll({
              top: offset,
              behavior: 'smooth',
            });

            this.scrollSubject
              .pipe(
                debounceTime(100) // Adjust debounce time as needed (in milliseconds)
              )
              .subscribe(() => {
                // Your existing scroll logic here
                const el = document.getElementById(paths.allergies);
                const ma = document.getElementById(paths.medicalAlert);

                if (ma) {
                  const rect = ma.getBoundingClientRect();
                  if (rect.top >= 120) {
                    this.selectedOption = this.submenu?.find(
                      (sm) => sm.path == paths.medicalAlert
                    )?.path;

                    this.selectedOptionChange.emit(this.selectedOption);
                  }
                }

                if (el) {
                  const rect = el.getBoundingClientRect();
                  if (rect.top >= 120) {
                    this.selectedOption = this.submenu?.find(
                      (sm) => sm.path == paths.allergies
                    )?.path;

                    this.selectedOptionChange.emit(this.selectedOption);
                  }
                }
              });
          }
        }
      });
    }, 500);
  }

  constructor(
    private readonly allergyService: AllergyService,
    private readonly medicalAlertService: MedicalAlertService
  ) {}

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    this.scrollSubject.next(event);
  }
}
