import { Component, Input } from '@angular/core';
import moment from 'moment';
import { ICDADetailed } from 'src/app/interfaces/SHL/ICDADetailed';

@Component({
  selector: 'app-shl-patient-summary',
  templateUrl: './shl-patient-summary.component.html',
  styleUrl: './shl-patient-summary.component.css',
})
export class ShlPatientSummaryComponent {
  @Input() data?: ICDADetailed;
  @Input() isLoading: boolean = false;

  isVisible: boolean = false;

  loadDetails() {
    this.isVisible = true;
  }

  handleCancel() {
    this.isVisible = false;
  }

  formatDate(dateString: string | null): string {
    if (!dateString) return '-';

    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return `${year}-${month}-${day}`;
  }
}
