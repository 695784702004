<nz-layout>
  <nz-content>
    <ng-container>
      <app-reusable-reverse-table title="Capnography" [rowNames]="[
          'Positive end-expiratory pressure (PEEP)',
          'End Tidal CO2',
          'Fraction of inhaled oxygen (FiO2)',
          'Tidal Volume (Vt)',
          'Respiratory Rate (RR)',
          'Pressure of inhaled oxygen (Paw)'
        ]" [rowFields]="[
          'positiveEndExpiratoryPressure',
          'endTidal',
          'fractionInhaledOxygen',
          'tidalVolume',
          'respiratoryRate',
          'pressureInhaledOxygen'
        ]" colIdentifier="date" [data]="capnography || []" [addComponent]="addComp" [deleteFun]="deleteFun"
        [isLoading]="isLoading" [openModalFun]="openModalFun" [isPreview]="false" />
    </ng-container>
  </nz-content>
</nz-layout>