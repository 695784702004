import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { Subject, combineLatest, debounceTime } from 'rxjs';
import { IModule } from 'src/app/interfaces/IModule';
import { ArterialBloodGasService } from 'src/app/services/arterialBloodGas.service';
import { GlasgowService } from 'src/app/services/glasgow.service';
import { VitalSignService } from 'src/app/services/vitalSign.service';
import { paths } from 'src/component-paths/component-paths';

@Component({
  selector: 'app-daily-monitoring-section',
  templateUrl: './daily-monitoring-section.component.html',
  styleUrls: ['./daily-monitoring-section.component.css'],
})
export class DailyMonitoringSectionComponent {
  @Input() submenu?: IModule[];

  componentPaths = paths;

  @Input() selectedOption?: string;
  @Output() selectedOptionChange = new EventEmitter<string>();

  ngOnInit() {}

  private scrollSubject = new Subject<Event>();

  ngAfterViewInit() {
    setTimeout(() => {
      combineLatest([
        this.abgService.arterialBloodGasLoadingSub$,
        this.glasgowService.glasgowLoadingSub$,
        this.vitalSignService.vitalSignLoadingSub$,
      ]).subscribe(([value1, value2, value3]) => {
        const sectionPath = this.submenu?.find(
          (sm) => sm.path == this.selectedOption
        )?.path;
        if (!value1 && !value2 && !value3 && sectionPath) {
          const element = document.getElementById(sectionPath);
          if (element) {
            // menu, patient header, title, margin
            const offset = element.offsetTop - 120 - 66 - 64 - 16;
            window.scrollTo(0, offset);

            this.scrollSubject
              .pipe(
                debounceTime(100) // Adjust debounce time as needed (in milliseconds)
              )
              .subscribe(() => {
                // Your existing scroll logic here
                const vs = document.getElementById(paths.vitalSigns);
                const abg = document.getElementById(paths.arterialBloodGas);
                const gcs = document.getElementById(paths.glasgow);

                if (gcs) {
                  const rect = gcs.getBoundingClientRect();
                  if (rect.top >= 120) {
                    this.selectedOption = this.submenu?.find(
                      (sm) => sm.path == paths.glasgow
                    )?.path;

                    this.selectedOptionChange.emit(this.selectedOption);
                  }
                }

                if (abg) {
                  const rect = abg.getBoundingClientRect();
                  if (rect.top >= 120) {
                    this.selectedOption = this.submenu?.find(
                      (sm) => sm.path == paths.arterialBloodGas
                    )?.path;

                    this.selectedOptionChange.emit(this.selectedOption);
                  }
                }

                if (vs) {
                  const rect = vs.getBoundingClientRect();
                  if (rect.top >= 120) {
                    this.selectedOption = this.submenu?.find(
                      (sm) => sm.path == paths.vitalSigns
                    )?.path;

                    this.selectedOptionChange.emit(this.selectedOption);
                  }
                }
              });
          }
        }
      });
    }, 500);
  }

  constructor(
    private readonly abgService: ArterialBloodGasService,
    private readonly glasgowService: GlasgowService,
    private readonly vitalSignService: VitalSignService
  ) {}

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    this.scrollSubject.next(event);
  }
}
